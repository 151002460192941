<template lang="pug">
div(class='overflow-hidden')
  div(class='overflow-x-auto')
    table(:class='alignmentClasses')
      tbody
        tr(v-for='row in data.rows' :key='row._uid')
          td(
            v-for='cell in row.cells'
            v-bind='bindStoryblokBridgeData(cell)'
            :key='cell._uid'
            :colspan='cell.columnSpan'
            :rowspan='cell.rowSpan'
            :class='cellClasses(cell)'
            class='border border-gray-darker py-2 px-3'
          )
            template(v-for='widget in cell.widgets')
              slot(:data='widget')
</template>

<script setup lang="ts">
const { $storyblok } = useNuxtApp()
const { bindStoryblokBridgeData } = $storyblok

interface TableCell extends Widgets {
  columnSpan: string
  rowSpan: string
  backgroundColor: BackgroundColor
}

interface TableRow extends Widget {
  cells: TableCell[]
}

interface TableWidget extends Widget {
  rows: TableRow[]
  alignment: string
}

const { data } = defineProps<{
  data: TableWidget
}>()

const alignmentClasses = computed(() => {
  switch (data.alignment) {
    case 'LEFT':
      return 'mr-auto'
    case 'RIGHT':
      return 'ml-auto'
    default:
      return 'mx-auto'
  }
})

function cellClasses(cell: TableCell) {
  return generateBackgroundColorClass(cell.backgroundColor)
}
</script>
